import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

// Import portfolio images
import portfolio_reviewpango from "../images/portfolio/logicbite_portfolio_reviewpango.png";
import portfolio_inventidad from "../images/portfolio/logicbite_portfolio_inventidad.png";
import portfolio_protonwallet from "../images/portfolio/logicbite_portfolio_protonwallet.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";

// Import Swiper
import SwiperCore, { Autoplay, Pagination, A11y } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/pagination/pagination.scss";

SwiperCore.use([Autoplay, Pagination, A11y]);

export function Portfolio(): JSX.Element {
    const [activeModal, setModal] = useState("disabled");

    const disable = () => {
        setModal("disabled");
    };

    return (
        <section className="section" id="gallery">
            <div className="container">
                <div className="content has-text-centered is-medium">
                    <h3 className="">Our work</h3>
                    <p className="">
                        Check out how we've helped other companies transform
                        their ideas into an inspiring reality
                    </p>
                </div>
                <div className="gallerySwiper portfolio">
                    <Swiper
                        spaceBetween={50}
                        slidesPerView={3}
                        pagination={{ clickable: true }}
                        scrollbar={{ draggable: true }}
                        autoplay={{ delay: 3000 }}
                        onSlideChange={() => console.log("slide change")}
                    >
                        {swiperSlide(portfolio_reviewpango, () => {
                            setModal("portfolio-1");
                        })}
                        {swiperSlide(portfolio_inventidad, () => {
                            setModal("portfolio-2");
                        })}
                        {swiperSlide(portfolio_protonwallet, () => {
                            setModal("portfolio-3");
                        })}
                        <br />
                        <br />
                    </Swiper>
                </div>

                {/* Portfolio slides */}
                <div className="modals">
                    {/* Portfolio 1  */}
                    <PortfolioModal
                        {...{
                            modalName: "portfolio-1",
                            modalTitle: "ReviewPango.com",
                            modalSubtitle:
                                "Display fresh reviews, customize how they show and make a good first impression to your website visitors",
                            technologiesUsed: ["React", "Node.JS"],
                            modalImage: portfolio_reviewpango,
                            modalDescription:
                                "We built the webapp that Reviewpango is based on, going from concept to idea and efficiently delivering on the promise of a simple yet highly intuitive app that people can use to get more engagement on their websites by aggregating customer reviews across many different services.",
                            activeModal,
                            disable,
                        }}
                    />
                    {/* Portfolio 2  */}
                    <PortfolioModal
                        {...{
                            modalName: "portfolio-2",
                            modalTitle: "Inventidad Website",
                            modalSubtitle:
                                "A small design study based in Mexico that aims to create the brands of tomorrow",
                            technologiesUsed: [
                                "HTML5/CSS3",
                                "JavaScript",
                                "Netlify",
                            ],
                            modalImage: portfolio_inventidad,
                            modalDescription:
                                "We partnered up with Inventidad to collaborate on the development of a high-converting, future proof, beautiful website that embodies the brand-centric mindset of their team. We worked hand in hand every step of the way and delivered a unique and simple website that serves as a great first impression of their quality work.",
                            activeModal,
                            disable,
                        }}
                    />
                    {/* Portfolio 3 */}
                    <PortfolioModal
                        {...{
                            modalName: "portfolio-3",
                            modalTitle: "Proton Wallet",
                            modalSubtitle:
                                "Proton Wallet is an open-source, simple to use and intuitive cryptocurrency wallet",
                            technologiesUsed: ["React", "Electron"],
                            modalImage: portfolio_protonwallet,
                            modalDescription:
                                "We started the development of a wallet for a recently launched cryptocurrency with a flourishing community of tinkerers as an open-source project. Soon, people from all walks of life around the world were working on what is now the most popular way to interact with the TurtleCoin network.",
                            activeModal,
                            disable,
                        }}
                    />
                </div>
            </div>
        </section>
    );
}

const swiperSlide = (image: string, onClick: () => void) => {
    return (
        <SwiperSlide onClick={onClick}>
            <div className="portfolio--item modal-button">
                <div className="card-image">
                    <figure className="image is-4by3">
                        <img src={image} alt="Placeholder" />
                    </figure>
                </div>
                <div className="portfolio--button">
                    <FontAwesomeIcon icon={faLongArrowAltRight} />
                </div>
            </div>
        </SwiperSlide>
    );
};

interface IModalOptions {
    modalName: string;
    modalTitle: string;
    modalSubtitle: string;
    technologiesUsed: string[];
    modalImage: string;
    modalDescription: string;
    activeModal: string;
    disable: () => void;
}

export function PortfolioModal(props: IModalOptions): JSX.Element {
    const {
        modalName,
        modalTitle,
        modalSubtitle,
        technologiesUsed,
        modalImage,
        modalDescription,
        activeModal,
        disable,
    } = props;
    return (
        <div
            className={`modal ${activeModal === modalName ? "is-active" : ""}`}
        >
            <div className="modal-background" onClick={disable}></div>
            <div className="modal-content">
                <div className="box portfolio--modal">
                    <div className="portfolio--modal--title">
                        <p className="title is-medium">{modalTitle}</p>
                        <p>{modalSubtitle}</p>
                    </div>
                    <div className="portfolio--modal--tags">
                        <p>Technologies used:&nbsp;&nbsp;</p>
                        <div className="tags">
                            {technologiesUsed.map((technology) => (
                                <span
                                    key={modalName + "-" + technology}
                                    className="tag"
                                >
                                    {technology}
                                </span>
                            ))}
                        </div>
                    </div>
                    <div className="portfolio--modal--description">
                        <img
                            src={modalImage}
                            alt="Placeholder"
                            className="float--left is-480x480"
                        />
                        <p>{modalDescription}</p>
                    </div>
                    <div className="portfolio--modal--buttons">
                        <a
                            href="#contact"
                            className="not-button"
                            onClick={disable}
                        >
                            Work with us{" "}
                            <span>
                                <FontAwesomeIcon icon={faLongArrowAltRight} />
                            </span>
                        </a>
                    </div>
                </div>
            </div>
            <button
                className="modal-close is-large"
                aria-label="close"
                onClick={disable}
            ></button>
        </div>
    );
}
