import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import laptopguy from "../images/laptop_guy.svg";

export function About(): JSX.Element {
    return (
        <section className="section" id="about">
            <div className="container">
                <div className="columns is-vcentered">
                    <div className="column">
                        <div className="box no-shadow">
                            <article className="media">
                                <div className="media-left">
                                    <div className="icon contour-icon">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                </div>
                                <div className="media-content">
                                    <div className="content">
                                        <h2>Concept</h2>
                                        <p>
                                            When it comes to a new project, we
                                            understand that it requires more
                                            than just coding and testing. We use
                                            our expertise to design exciting
                                            solutions to your brand's specific needs
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="box no-shadow">
                            <article className="media">
                                <div className="media-left">
                                    <div className="icon contour-icon">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                </div>
                                <div className="media-content">
                                    <div className="content">
                                        <h2>Design</h2>
                                        <p>
                                            We strive to deliver beautiful and
                                            usable products that solve your
                                            users' problems and help you achieve
                                            your business's goals. We make sure
                                            your product is meaningful and
                                            accessible
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="box no-shadow">
                            <article className="media">
                                <div className="media-left">
                                    <div className="icon contour-icon">
                                        <FontAwesomeIcon icon={faCheck} />
                                    </div>
                                </div>
                                <div className="media-content">
                                    <div className="content">
                                        <h2>Development</h2>
                                        <p>
                                            Successful products are not just
                                            visually appealing to your audience.
                                            We make sure your project is also
                                            highly usuable, reliable and
                                            scalable so that your business can
                                            achieve it's goals
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                    <div className="column">
                        <img
                            src={laptopguy}
                            className="float--right"
                            width="500px"
                            alt={"guy with laptop"}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
