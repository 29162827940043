import React, { Fragment } from "react";
import { faGithub, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import logo from "../images/logicbite_horizontal_color.svg";

export function Footer(): JSX.Element {
    return (
        <Fragment>
            <section className="section" id="footer-top">
                <div className="container">
                    <div className="columns">
                        <div className="column"></div>
                        <div className="column is-one-fifth">
                            <div className="content">
                                <h4>Company</h4>
                                <ul>
                                    <li>
                                        <a href="#about">About us</a>
                                    </li>
                                    <li>
                                        <a href="#gallery">Portfolio</a>
                                    </li>
                                    <li>
                                        <a href="#features">Services</a>
                                    </li>
                                    <li>
                                        <a href="#process">Process</a>
                                    </li>
                                    <li>
                                        <a href="#contact">Contact</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="column is-one-fifth">
                            <div className="content">
                                <h4>Technology</h4>
                                <ul>
                                    <li>
                                        <div>Node JS</div>
                                    </li>
                                    <li>
                                        <div>React JS</div>
                                    </li>
                                    <li>
                                        <div>Electron JS</div>
                                    </li>
                                    <li>
                                        <div>Typescript</div>
                                    </li>
                                    <li>
                                        <div>Golang</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="column is-one-quarter has-text-right footer-details">
                            <div className="footer-brand">
                                <img
                                    src={logo}
                                    width="132"
                                    alt={"LogicBite logo"}
                                />
                            </div>
                            <div className="footer-social">
                                <a target="_blank" href="https://github.com/logicbite/">
                                    <FontAwesomeIcon icon={faGithub} />
                                </a>
                                <a target="_blank" href="https://instagram.com/logicbite/">
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </div>
                            <div className="footer-info">
                                <ul>
                                    <li>
                                        <a
                                            href="mailto:hello@logicbite.org"
                                            target="blank"
                                        >
                                            hello@logicbite.org
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section" id="footer-bottom">
                <div className="content has-text-centered">
                    <p>© 2020 LogicBite. All rights reserved.</p>
                </div>
            </section>
        </Fragment>
    );
}
