import React from "react";

import electron from "../images/electron.svg";
import nodejs from "../images/nodejs.svg";
import typescript from "../images/typescript.svg";
import react from "../images/react.svg";
import webpack from "../images/webpack.svg";
import bulma from "../images/bulma.svg";
import chemlab from "../images/chem_lab.svg";

export function Technology(): JSX.Element {
    return (
        <section className="section" id="technology">
            <div className="container">
                <div className="content is-medium has-text-centered">
                    <h3>Only the best tools</h3>
                    <p>
                        Carefully hand‑picked and mastered to develop
                        world‑changing brands & software
                    </p>
                </div>
                <div className="columns is-vcentered">
                    <div className="column">
                        <div className="columns is-mobile is-centered">
                            <div className="column is-one-third">
                                <div className="">
                                    <img
                                        src={webpack}
                                        className="contour-icon"
                                        alt={"webpack logo"}
                                    />
                                </div>
                            </div>
                            <div className="column is-one-third">
                                <div className="">
                                    <img
                                        src={typescript}
                                        className="contour-icon"
                                        alt={"typescript logo"}
                                    />
                                </div>
                            </div>
                            <div className="column is-one-third">
                                <div className="">
                                    <img
                                        src={react}
                                        className="contour-icon"
                                        alt={"react logo"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="columns is-mobile is-centered">
                            <div className="column is-one-third">
                                <div className="">
                                    <img
                                        src={electron}
                                        className="contour-icon"
                                        alt={"electron logo"}
                                    />
                                </div>
                            </div>
                            <div className="column is-one-third">
                                <div className="">
                                    <img
                                        src={bulma}
                                        className="contour-icon"
                                        alt={"bulma logo"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="columns is-mobile is-centered">
                            <div className="column is-one-third">
                                <div className="">
                                    <img
                                        src={nodejs}
                                        className="contour-icon"
                                        alt={"bulma logo"}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="column">
                        <img
                            src={chemlab}
                            className="float--right"
                            width="500px"
                            alt={"experiment illustration"}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
}
