import React from "react";

import webDev from "../images/icon-web-dev.svg";
import appMobile from "../images/icon-mobile-app.svg";
import appDesktop from "../images/icon-desktop-app.svg";
import uiux from "../images/icon-uiux.svg";

export function Features(): JSX.Element {
    return (
        <section className="section bg--primary" id="features">
            <div className="container">
                <div className="columns is-vcentered">
                    <div className="column is-one-quarter">
                        <div className="content is-light is-medium ">
                            <h3 className="">Create, transform, innovate</h3>
                            <p className="">
                                We've got your back: whether you need to build
                                your idea from scratch, or want to transform an 
                                already existing one to a highly scalable and engaging
                                brand, we're here for you
                            </p>
                            <button className="button is-negative">
                                <span>Contact us</span>
                            </button>
                        </div>
                    </div>
                    <div className="column">
                        <div className="columns">
                            <div className="column">
                                <div className="box">
                                    <article className="media">
                                        <div className="media-left">
                                            <img
                                                src={webDev}
                                                className=""
                                                alt={"web developer"}
                                            />
                                        </div>
                                        <div className="media-content">
                                            <div className="content">
                                                <h4>Web Development</h4>
                                                <p>
                                                    We build highly customized, user-centric websites using the best approach, technology and style to fit you, your bran, the solution, and the end-user
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="column">
                                
                                <div className="box">
                                    <article className="media">
                                        <div className="media-left">
                                            <img
                                                src={uiux}
                                                className=""
                                                alt={"user interface designs"}
                                            />
                                        </div>
                                        <div className="media-content">
                                            <div className="content">
                                                <h4>Identity Design</h4>
                                                <p>
                                                    We design & implement every aspect of brand identity. From logo design and print, to web, animation, and video – we make your brand come to life
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                            <div className="column">
                                <div className="box">
                                    <article className="media">
                                        <div className="media-left">
                                            <img
                                                src={appDesktop}
                                                className=""
                                                alt={"desktop app"}
                                            />
                                        </div>
                                        <div className="media-content">
                                            <div className="content">
                                                <h4>App Development</h4>
                                                <p>
                                                    We design and build full-stack, carefully crafted and tested web & mobile apps, the code behind your platform will be as reliable as it gets.
                                                </p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
