import React, { useState } from "react";
import pcrocket from "../images/pc_rocket.svg";
import * as uuid from "uuid";

import ax from "axios";

interface IFormValues {
    name: string;
    email: string;
    message: string;
    source: string;
}

interface IEmail {
    name: string;
    recipient: string;
    subject: string;
    message: string;
}

interface IDetails {
    [index: string]: string;
    name: string;
    email: string;
    message: string;
    source: string;
}

const detailsToString = (details: IDetails): string => {
    let output = "";
    for (const key in details) {
        output += `${key}: ${details[key]}\n`;
    }
    return output;
};

export function Contact(): JSX.Element {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [source, setSource] = useState("");

    const [errText, setErrText] = useState("");
    const [successText, setSuccessText] = useState("");
    const [sending, setSending] = useState(false);

    const resetForm = () => {
        setName("");
        setEmail("");
        setMessage("");
        setSource("");
    };

    async function handleSubmit() {
        setSending(true);
        const details = { name, email, message, source };

        const data: any = {
            name: "LogicBite LLC Robot",
            recipient: "hello@logicbite.org",
            subject: "ATTN: logicbite.org contact form inquiry #" + uuid.v4(),
            message:
                "Hey Sales Team! Just thought you might like to know somebody filled out a contact form on the website. Here's all the details they gave:\n" +
                detailsToString(details),
        };

        try {
            await ax.post("https://api.logicbite.org/email/v1/send", data, {
                headers: {
                    "x-api-key":
                        process.env.REACT_APP_EMAIL_API_KEY,
                },
            });
            resetForm();
            setErrText("");
            setSuccessText(
                "Your email was sent! A team member will get back to you as soon as humanly possible."
            );
        } catch (err) {
            setSuccessText("");
            setErrText(
                "There was an error delivering your mail. Try giving us a call at 720-487-9339. Sorry about that! :("
            );
            console.error(err);
        }
        setSending(false);
    }

    return (
        <section className="section bg--light" id="contact">
            <div className="container">
                <div className="columns is-centered is-vcentered">
                    <div className="column is-half">
                        <img
                            src={pcrocket}
                            className=""
                            width="500px"
                            alt={"rocket blasting off"}
                        />
                    </div>

                    <div className="column is-two-fifths">
                        <div className="box">
                            <div className="content has-text-centered is-medium">
                                <h3 className="">Work with us</h3>
                            </div>

                            {errText !== "" && (
                                <div className="notification is-danger">
                                    {errText}
                                </div>
                            )}

                            {successText !== "" && (
                                <div className="notification is-success">
                                    {successText}
                                </div>
                            )}

                            <div className="form" id="contactForm">
                                <div className="field">
                                    <div className="field-group">
                                        <input
                                            className="input-field"
                                            type="text"
                                            id="name"
                                            name="name"
                                            placeholder=" "
                                            value={name}
                                            onChange={(event) => {
                                                setName(event.target.value);
                                            }}
                                        />
                                        <label
                                            className="input-label"
                                            htmlFor="name"
                                        >
                                            Your Name*
                                        </label>
                                        <div className="input-bar"></div>
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="field-group">
                                        <input
                                            className="input-field"
                                            type="email"
                                            id="email"
                                            name="email"
                                            placeholder=" "
                                            value={email}
                                            onChange={(event) => {
                                                setEmail(event.target.value);
                                            }}
                                        />
                                        <label
                                            className="input-label"
                                            htmlFor="email"
                                        >
                                            Your Email*
                                        </label>
                                        <div className="input-bar"></div>
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="field-group">
                                        <input
                                            className="input-field"
                                            type="text"
                                            id="service"
                                            name="service"
                                            placeholder=" "
                                            value={message}
                                            onChange={(event) => {
                                                setMessage(event.target.value);
                                            }}
                                        />
                                        <label
                                            className="input-label"
                                            htmlFor="service"
                                        >
                                            What can we help with?*
                                        </label>
                                        <div className="input-bar"></div>
                                    </div>
                                </div>

                                <div className="field">
                                    <div className="field-group">
                                        <input
                                            className="input-field"
                                            type="text"
                                            id="source"
                                            name="source"
                                            placeholder=" "
                                            value={source}
                                            onChange={(event) => {
                                                setSource(event.target.value);
                                            }}
                                        />
                                        <label
                                            className="input-label"
                                            htmlFor="source"
                                        >
                                            How did you hear about us?
                                        </label>
                                        <div className="input-bar"></div>
                                    </div>
                                </div>

                                <div className="control">
                                    <button
                                        className={`button is-link ${
                                            sending ? "is-loading" : ""
                                        }`}
                                        onClick={() => {
                                            handleSubmit();
                                        }}
                                    >
                                        Let's get in touch
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
