import {
    faChevronDown,
    faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Typewriter from "typewriter-effect";
import logo from "../images/logicbite_horizontal_color.svg";

export function Hero(): JSX.Element {
    const [menuOpen, setMenuOpen] = useState(false);

    return (
        <section className="hero is-fullheight" id="home">
            <div className="hero-head">
                <header className="navbar">
                    <div className="container">
                        <div className="navbar-brand">
                            <a href="/" className="navbar-item">
                                <img src={logo} width="132" alt={"logo"} />
                            </a>
                            <span
                                className={`navbar-burger burger ${
                                    menuOpen ? "is-active" : ""
                                }`}
                                onClick={() => {
                                    setMenuOpen(!menuOpen);
                                }}
                                data-target="navbarMenuHeroC"
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </span>
                        </div>
                        <div
                            id="navbarMenuHeroC"
                            className={`navbar-menu ${
                                menuOpen ? "is-active" : ""
                            }`}
                        >
                            <div className="navbar-end">
                                <a className="navbar-item" href="#about">
                                    About us
                                </a>
                                <a className="navbar-item" href="#gallery">
                                    Our Work
                                </a>
                                <a className="navbar-item" href="#features">
                                    Services
                                </a>
                                <span className="navbar-item">
                                    <a
                                        className="button is-primary"
                                        href="#contact"
                                    >
                                        <span>Contact us</span>
                                    </a>
                                </span>
                            </div>
                        </div>
                    </div>
                </header>
            </div>

            <div className="hero-body">
                <div className="container">
                    <div className="columns">
                        <div className="column">
                            <div className="content is-large">
                                <h1 className="is-hero-title">
                                    Creative&nbsp;
                                    <Typewriter
                                        onInit={undefined as any}
                                        options={{
                                            strings: [
                                                "agency",
                                                "design",
                                                "development",
                                                "branding",
                                                "strategy",
                                                "code",
                                                "improvement",
                                                "help",
                                            ],
                                            autoStart: true,
                                            loop: true,
                                        }}
                                    />
                                </h1>
                                <p className="">
                                    We are ready to join your next project.
                                </p>
                                <a href="#gallery" className="not-button">
                                    Our portfolio{" "}
                                    <span>
                                        <FontAwesomeIcon
                                            icon={faLongArrowAltRight}
                                        />
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="column"></div>
                    </div>
                </div>
                <a className="has-text-centered" id="scrollArrow" href="#about">
                    <FontAwesomeIcon icon={faChevronDown} />
                </a>
            </div>
        </section>
    );
}
