import React from "react";

// Stylesheets
import "./stylesheets/style.scss";

// Components
import { Hero } from "./components/Hero";
import { About } from "./components/About";
import { Portfolio } from "./components/Portfolio";
import { Process } from "./components/Process";
import { Technology } from "./components/Technology";
import { Footer } from "./components/Footer";
import { Contact } from "./components/Contact";
import { Features } from "./components/Features";

function App() {
    return (
        <div id="site">
            <Hero />
            <About />
            <Portfolio />
            <Features />
            <Process />
            <Technology />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;
