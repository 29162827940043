import React from "react";

// Icons
import problem from "../images/icon-problem.svg";
import idea from "../images/icon-idea.svg";
import solution from "../images/icon-solution.svg";

export function Process(): JSX.Element {
    return (
        <section className="section bg--light" id="process">
            <div className="container">
                <div className="columns">
                    <div className="column">
                        <div className="content is-medium">
                            <h3 className="">How we work</h3>
                        </div>
                        <div className="columns">
                            <div className="column">
                                <div className="content">
                                    <img
                                        src={problem}
                                        className="contour-icon"
                                        alt={"problem illustration"}
                                    />
                                    <h4 className="">Problem</h4>
                                    <p>
                                        Everyone's got needs, tell us yours. We
                                        begin by recognizing your needs and demands,
                                        then scope a working plan to achieve the
                                        needed results
                                    </p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="content">
                                    <img
                                        src={idea}
                                        className="contour-icon"
                                        alt={"idea illustration"}
                                    />
                                    <h4 className="">Idea</h4>
                                    <p>
                                        Let's define the methodology. From
                                        knowledge acquisition to setting up the
                                        tools needed for the job, we create the 
                                        building blocks best suited for your project
                                    </p>
                                </div>
                            </div>
                            <div className="column">
                                <div className="content">
                                    <img
                                        src={solution}
                                        className="contour-icon"
                                        alt={"sollution illustration"}
                                    />
                                    <h4 className="">Solution</h4>
                                    <p>
                                        Seeking to achieve the best
                                        quality and results, we work on your project
                                        so that it's ready for the world to see it 
                                        and to create actual value for your business
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
